<template>
    <div class="table-box">
        <div class="parent_box">
            <img src="@/img/waterfalls-h.png" alt="" />
            <p>
                <span style="margin-left: 5px">结算编号:</span
                >{{ tableData.settleNo || '- -' }}
            </p>
            <p style="min-width: 135px">
                <span>交易时间:</span
                >{{
                    tableData.tradeTime
                        ? tableData.tradeTime.slice(0, 10)
                        : '- -'
                }}
            </p>
            <p><span>回款金额:</span>{{ tableData.paymentAmount || '- -' }}</p>
            <p style="min-width: 220px">
                <span>客户名称:</span>{{ tableData.companyName || '- -' }}
            </p>
            <p style="min-width: 190px">
                <span>合同编号:</span>{{ tableData.contractNo || '- -' }}
            </p>
            <p><span>合同金额:</span>{{ tableData.contractAmount || '- -' }}</p>
        </div>
        <SubTable ref="subTable" v-show="isShow" @again="getData"></SubTable>
    </div>
</template>

<script>
import SubTable from './subtable.vue';
import { reportList } from '@/api/report/report.js';
export default {
    name: '',
    props: ['tableData'],
    data() {
        return {
            tableRow: {},
            dialog: false,
            btnP: {},
            isShow: false,
        };
    },
    components: { SubTable },
    created() {},
    mounted() {
        if (
            this.tableData.billSettleInfoBOList &&
            this.tableData.billSettleInfoBOList.length > 0
        ) {
            this.isShow = true;
            this.getData();
        }
    },
    methods: {
        showMore() {
            this.isShow = !this.isShow;
        },
        getData() {
            this.$refs.subTable.getData(
                this.tableData.billSettleInfoBOList,
                this.tableData.waterId
            );
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 13px !important;
}
.table-box {
    width: 100%;

    overflow: hidden;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        vertical-align: -1px;
    }
}
.parent_box {
    background-color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 1px;
    flex-wrap: nowrap;
    position: relative;
    > p {
        flex: 1;
        font-size: 12px;
        font-weight: 600;
        color: #333;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        white-space: nowrap;
        span {
            margin: 0 0 0 6px;
            display: block;
            width: 55px;
        }
    }
    > div {
        flex: 1;
        font-size: 12px;
        font-weight: 600;
        color: #333;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        white-space: nowrap;
        > p {
        }
        > span {
            color: #999;
            font-weight: normal;
            margin: 0 0 0 6px;
            display: block;
            width: 43px;
        }
        .label_del_box {
            color: #2370eb;
            border: 1px solid #2370eb;
            font-weight: normal;
            padding: 4px 8px;
            position: relative;
        }
    }
    .more_info {
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }
}
/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.table-box .el-button {
    padding: 7px 15px !important;
}
</style>
