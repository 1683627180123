<template>
    <div class="deal_with_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>
                        <i class="iconfont icon-feiyongmingxi"></i>
                        操作明细
                    </span>
                </p>
                <el-button
                    @click="onClose()"
                    size="mini"
                    type="primary"
                    icon="el-icon-arrow-left"
                    style="
                        background: #fff;
                        color: #2370eb;
                        border-color: #2370eb;
                    "
                    >返回列表</el-button
                >
            </div>
        </div>

        <div class="table_con">
            <Table
                v-for="item in tableData"
                :key="item.id"
                :tableData="item"
            ></Table>
        </div>

        <div class="bottom-but">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
            <span>
                <!-- <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="onClose"
                    >取消</el-button
                > -->
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                    "
                    size="mini"
                    v-if="status == 1 && btnP.send"
                    @click="onSend"
                    >发送账单</el-button
                ></span
            >
        </div>
        <!-- 发送 -->
        <el-dialog
            :visible.sync="dialogSend"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    发送账单
                </div>
            </template>
            <div class="dialog_info">
                <p>
                    <i
                        class="el-icon-warning-outline"
                        style="color: #ff8a00"
                    ></i>
                    即将发送当前账单，是否确认？
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="margin-right: 16px; background-color: #2370eb"
                    round
                    @click="onSubmitSend"
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { billSettleList, billUpdate } from '@/api/count/business.js';
import Table from './components/table.vue';
export default {
    data() {
        return {
            tableData: [],
            currentPage: 1,
            pagesize: 20,
            total: 0,
            tableRow: {},
            dialogSend: false,
            status: '',
            btnP: {},
        };
    },
    components: {
        Table,
    },
    created() {},
    mounted() {
        this.billSettleList();
    },
    methods: {
        onClose() {
            this.$router.go(-1);
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.billSettleList();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.billSettleList();
        },
        billSettleList() {
            this.status = this.$route.query.status;
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            let data = {
                param: {
                    billId: this.$route.query.id,
                },
                pageSize: this.pagesize,
                pageNum: this.currentPage,
            };
            billSettleList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },
        onSend() {
            this.dialogSend = true;
        },
        onSubmitSend() {
            let data = {
                param: {
                    id: this.$route.query.id,
                    status: 2,
                },
            };
            billUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        handleClose() {
            this.dialogGenerate = false;
            this.dialogSend = false;
            this.onClose();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.deal_with_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 14px;

            margin: 0 16px;
            i {
                color: #2370eb;
                font-size: 24px;
                vertical-align: -4px;
                font-weight: normal;
            }
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .left {
            }
            .add {
                //  border-color: #2370eb;
                //  color: #2370eb;
            }
            button {
                font-size: 12px;
                padding: 0 7px 0 12px;
                height: 30px;
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #2370eb;
                cursor: pointer;
                color: #2370eb;
                margin-right: 16px;
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 20px 0 12px;
            height: 26px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            background: #2370eb;
            i {
                margin-right: 6px;
                font-size: 12px;
            }
        }
    }
    .table-box + .table-box {
        margin-top: 12px;
    }
    .table_con {
        height: calc(100% - 60px);
        overflow-y: auto;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #e5e5e5;
        position: relative;
        button {
            width: 90px;
        }
        span {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.dialog_info {
    width: 85%;
    margin: 25px auto 0;
    padding-bottom: 110px;
    font-size: 14px;
    color: #333333;
    p {
        margin-bottom: 16px;
        font-weight: 600;
    }
    span {
        display: inline-block;
        width: 215px;
        height: 48px;
        background: #f9fafc;
        border-radius: 8px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        i {
            color: #2370eb;
        }
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
