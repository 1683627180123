<template>
    <div class="table-box2">
        <div v-for="(item, index) in tableData" :key="item.id">
            <p>
                <i>{{ index + 1 }}</i>
                <span>产品名称:</span>
                <em style="min-width: 80px">
                    {{
                        item.productAttributeRelationBOList &&
                        item.productAttributeRelationBOList.length > 0
                            ? item.productAttributeRelationBOList[0].productName
                            : '- -'
                    }}
                </em>
            </p>
            <p>
                <span>购买类型:</span>
                <em>
                    {{ returnOrderType(item) }}
                </em>
            </p>
            <p>
                <span>用户数:</span>
                <em>
                    {{
                        item.productCategory != 5 &&
                        item.productCategory != 6 &&
                        item.productCategory != 8 &&
                        item.productCategory != 9 &&
                        item.productCategory != 12 &&
                        item.productCategory != 3
                            ? item.orderNumber
                            : '- -'
                    }}
                </em>
            </p>
            <p>
                <span>年限:</span>
                <em>
                    {{
                        item.productCategory != 6 &&
                        item.productCategory != 9 &&
                        item.productCategory != 11 &&
                        item.productType != 3
                            ? item.orderTerm
                            : '- -'
                    }}
                </em>
            </p>
            <p>
                <span>指导价:</span>
                <em>
                    {{ item.guideAmount }}
                </em>
            </p>
            <p>
                <span>应收金额:</span>
                <em>
                    {{ item.receivableAmount }}
                </em>
            </p>
            <p>
                <span>实收金额:</span>
                <em>
                    <i class="el-icon-success" v-show="item.tag1"></i>
                    <el-input
                        size="small"
                        v-model.number="item.actualAmount"
                        :disabled="
                            !(
                                btnP.money &&
                                status == 1 &&
                                item.confirmStatus == 1
                            )
                        "
                        style="width: 120px; margin-left: 10px"
                        onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        @blur="updateSet(item)"
                        @input="
                            item.actualAmount = item.actualAmount.replace(
                                /[^0-9.]/g,
                                ''
                            )
                        "
                        v-if="
                            btnP.money && status == 1 && item.confirmStatus == 1
                        "
                    ></el-input>
                    <div v-else>&nbsp;&nbsp;&nbsp;{{ item.actualAmount }}</div>
                </em>
            </p>
            <p>
                <span>结算成本:</span>
                <em>
                    <i class="el-icon-success" v-show="item.tag2"></i>
                    <el-input
                        size="small"
                        v-model.number="item.formerAmount"
                        :disabled="
                            !(
                                btnP.cost &&
                                status == 1 &&
                                item.confirmStatus == 1
                            )
                        "
                        style="width: 120px; margin-left: 10px"
                        @blur="updateSet2(item)"
                        @input="
                            item.formerAmount = item.formerAmount.replace(
                                /[^0-9.]/g,
                                ''
                            )
                        "
                        v-if="
                            btnP.cost && status == 1 && item.confirmStatus == 1
                        "
                    ></el-input>
                    <div v-else>&nbsp;&nbsp;&nbsp;{{ item.formerAmount }}</div>
                </em>
            </p>
            <p>
                <span>状态:</span>
                <em>
                    {{
                        item.confirmStatus == 1
                            ? '待确认'
                            : item.confirmStatus == 2
                            ? '已确认'
                            : item.confirmStatus == 3
                            ? '已驳回'
                            : item.confirmStatus == 4
                            ? '已删除'
                            : '- -'
                    }}
                </em>
            </p>
            <p>
                <span>反馈说明:</span>

                <em style="min-width: 80px">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.remark"
                        placement="top"
                    >
                        <em>
                            {{ item.remark.slice(0, 4) || '- -' }}
                        </em>
                    </el-tooltip>
                </em>
            </p>
        </div>
    </div>
</template>

<script>
import { billSettleInfoUpdate } from '@/api/count/business.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            tableRow: {},
            waterId: '',
            btnP: {},
            status: this.$route.query.status,
        };
    },
    components: {},
    created() {},
    methods: {
        returnOrderType(data) {
            let a = '- -';
            data.productAttributeRelationBOList.forEach((item) => {
                if (item.attributeColumnName == 'productType') {
                    item.productAttributeValueBOList &&
                    item.productAttributeValueBOList.length > 0
                        ? item.productAttributeValueBOList.forEach((itm) => {
                              if (data.orderType == itm.attributeValue) {
                                  a = itm.attributeValueName;
                              }
                          })
                        : '- -';
                }
            });
            return a;
        },
        getData(data, waterId) {
            data.forEach((item) => {
                item.tag1 = false;

                item.tag2 = false;
            });
            this.tableData = data;
            this.waterId = waterId;
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
        },

        // onSet(row) {
        //     row.disabled1 = false;
        //     this.tableData = JSON.parse(JSON.stringify(this.tableData));
        // },
        updateSet(row) {
            let data = {
                param: {
                    id: row.id,
                    orderId: row.orderId,
                    formerAmount: row.formerAmount,
                    actualAmount: row.actualAmount,
                    waterId: this.waterId,
                    billId: this.$route.query.id,
                },
            };
            billSettleInfoUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');

                    this.tableData = JSON.parse(JSON.stringify(this.tableData));
                }
            });
        },
        // onSet2(row) {
        //     this.tableData = JSON.parse(JSON.stringify(this.tableData));
        // },
        updateSet2(row) {
            let data = {
                param: {
                    id: row.id,
                    orderId: row.orderId,
                    formerAmount: row.formerAmount,
                    actualAmount: row.actualAmount,
                    waterId: this.waterId,
                    billId: this.$route.query.id,
                },
            };
            billSettleInfoUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');

                    this.tableData = JSON.parse(JSON.stringify(this.tableData));
                }
            });
        },
        againGetData() {
            this.$emit('again');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/ .el-table th {
    font-size: 12px !important;
    font-weight: normal !important;
}
/deep/ .el-table {
    font-size: 12px !important;
}
.table-box2 {
    width: 100%;

    position: relative;
    > div {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 0 20px;
        font-size: 12px;
        background-color: #fff;
        p {
            flex: 1;
            color: #666;
            font-weight: normal;
            white-space: nowrap;
            display: flex;
            align-items: center;
            i {
                color: #2370eb;
                font-size: 13px;
                font-style: normal;
            }
            span {
                white-space: nowrap;
                margin: 0 8px;
            }
            em {
                flex: 1;
                white-space: nowrap;
                font-style: normal;
                position: relative;
            }
        }
    }
}
.el-icon-success {
    color: #2370eb;
    position: absolute;
    left: -5px;
    top: 11px;
}
</style>
