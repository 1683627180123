var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-box2"},_vm._l((_vm.tableData),function(item,index){return _c('div',{key:item.id},[_c('p',[_c('i',[_vm._v(_vm._s(index + 1))]),_c('span',[_vm._v("产品名称:")]),_c('em',{staticStyle:{"min-width":"80px"}},[_vm._v(" "+_vm._s(item.productAttributeRelationBOList && item.productAttributeRelationBOList.length > 0 ? item.productAttributeRelationBOList[0].productName : '- -')+" ")])]),_c('p',[_c('span',[_vm._v("购买类型:")]),_c('em',[_vm._v(" "+_vm._s(_vm.returnOrderType(item))+" ")])]),_c('p',[_c('span',[_vm._v("用户数:")]),_c('em',[_vm._v(" "+_vm._s(item.productCategory != 5 && item.productCategory != 6 && item.productCategory != 8 && item.productCategory != 9 && item.productCategory != 12 && item.productCategory != 3 ? item.orderNumber : '- -')+" ")])]),_c('p',[_c('span',[_vm._v("年限:")]),_c('em',[_vm._v(" "+_vm._s(item.productCategory != 6 && item.productCategory != 9 && item.productCategory != 11 && item.productType != 3 ? item.orderTerm : '- -')+" ")])]),_c('p',[_c('span',[_vm._v("指导价:")]),_c('em',[_vm._v(" "+_vm._s(item.guideAmount)+" ")])]),_c('p',[_c('span',[_vm._v("应收金额:")]),_c('em',[_vm._v(" "+_vm._s(item.receivableAmount)+" ")])]),_c('p',[_c('span',[_vm._v("实收金额:")]),_c('em',[_c('i',{directives:[{name:"show",rawName:"v-show",value:(item.tag1),expression:"item.tag1"}],staticClass:"el-icon-success"}),(
                        _vm.btnP.money && _vm.status == 1 && item.confirmStatus == 1
                    )?_c('el-input',{staticStyle:{"width":"120px","margin-left":"10px"},attrs:{"size":"small","disabled":!(
                            _vm.btnP.money &&
                            _vm.status == 1 &&
                            item.confirmStatus == 1
                        ),"onkeyup":"value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')"},on:{"blur":function($event){return _vm.updateSet(item)},"input":function($event){item.actualAmount = item.actualAmount.replace(
                            /[^0-9.]/g,
                            ''
                        )}},model:{value:(item.actualAmount),callback:function ($$v) {_vm.$set(item, "actualAmount", _vm._n($$v))},expression:"item.actualAmount"}}):_c('div',[_vm._v("   "+_vm._s(item.actualAmount))])],1)]),_c('p',[_c('span',[_vm._v("结算成本:")]),_c('em',[_c('i',{directives:[{name:"show",rawName:"v-show",value:(item.tag2),expression:"item.tag2"}],staticClass:"el-icon-success"}),(
                        _vm.btnP.cost && _vm.status == 1 && item.confirmStatus == 1
                    )?_c('el-input',{staticStyle:{"width":"120px","margin-left":"10px"},attrs:{"size":"small","disabled":!(
                            _vm.btnP.cost &&
                            _vm.status == 1 &&
                            item.confirmStatus == 1
                        )},on:{"blur":function($event){return _vm.updateSet2(item)},"input":function($event){item.formerAmount = item.formerAmount.replace(
                            /[^0-9.]/g,
                            ''
                        )}},model:{value:(item.formerAmount),callback:function ($$v) {_vm.$set(item, "formerAmount", _vm._n($$v))},expression:"item.formerAmount"}}):_c('div',[_vm._v("   "+_vm._s(item.formerAmount))])],1)]),_c('p',[_c('span',[_vm._v("状态:")]),_c('em',[_vm._v(" "+_vm._s(item.confirmStatus == 1 ? '待确认' : item.confirmStatus == 2 ? '已确认' : item.confirmStatus == 3 ? '已驳回' : item.confirmStatus == 4 ? '已删除' : '- -')+" ")])]),_c('p',[_c('span',[_vm._v("反馈说明:")]),_c('em',{staticStyle:{"min-width":"80px"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.remark,"placement":"top"}},[_c('em',[_vm._v(" "+_vm._s(item.remark.slice(0, 4) || '- -')+" ")])])],1)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }